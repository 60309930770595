import { NotificationRequestModel } from './Models';
import axios from 'axios';
import { Config } from '../../../config';

export async function fetchNotifications(): Promise<Array<NotificationRequestModel>> {
  const response = await axios.get(`${Config.api_url}/notifications`);
  return await response.data.value;
}

export async function fetchHasUnreadNotifications(): Promise<boolean> {
  const response = await axios.get(`${Config.api_url}/notifications/unread`);
  const data: { unread: string } = response.data.value;

  return parseInt(data.unread) != 0;
}

export async function clearUnreadNotifications(): Promise<any> {
  return await axios.delete(`${Config.api_url}/notifications/unread`);
}
