import React, { FC } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive, KTSVG, WithChildren } from '../../../../helpers';
import { useLayout } from '../../../core';
import { useAuth } from '../../../../../app/modules/auth';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  id?: string;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  id,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation();
  const { config } = useLayout();
  const { app } = config;

  const isActive = checkIsActive(pathname, to);

  return (
    <div className="menu-item !w-full !my-[12px]">
      <Link
        className={`flex items-center !z-50 !w-[192px] !px-[16px] ${
          isActive
            ? 'main-gradient !text-white text-body14 !font-semibold !rounded-2xl !py-[14px] !drop-shadow-selected'
            : '!py-[5px]'
        }`}
        to={to}
        id={id}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {/* {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className="menu-icon !text-priBlack">
            <KTSVG path={icon} className="svg-icon-2 !text-priBlack !fill-priBlack" />
          </span>
        )} */}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && fontIcon && (
          <i className={`${fontIcon} !text-[20px] mr-3 !text-white`}></i>
        )}
        <span className={`!text-body14 !font-normal !text-white`}>{title}</span>
      </Link>
      {children}
    </div>
  );
};

export { SidebarMenuItem };
