import { useAuth, UserTypeEnum } from '../../../../../app/modules/auth';
import { CandidateSidebarMainMenu } from './CandidateSidebarMainMenu';
import { CustomerSidebarMainMenu } from './CustomerSidebarMainMenu';

const SidebarMenu = () => {
  const { accountType } = useAuth();
  return (
    <div className="">
      <div
        id="distro_app_sidebar_menu_wrapper"
        className={`app-sidebar-wrapper hover-scroll-overlay-y my-5 !w-[212px] !bg-priDark`}
        data-distro-scroll="true"
        data-distro-scroll-activate="true"
        data-distro-scroll-height="auto"
        data-distro-scroll-dependencies="#distro_app_sidebar_logo, #distro_app_sidebar_footer"
        data-distro-scroll-wrappers="#distro_app_sidebar_menu"
        data-distro-scroll-offset="5px"
        data-distro-scroll-save-state="true"
      >
        <div
          className={`menu menu-column menu-rounded menu-sub-indention !w-[212px] !bg-priDark !text-white`}
          id="#distro_app_sidebar_menu"
          data-distro-menu="true"
          data-distro-menu-expand="false"
        >
          {accountType === UserTypeEnum.CANDIDATE ? (
            <CandidateSidebarMainMenu />
          ) : (
            <CustomerSidebarMainMenu />
          )}
        </div>
      </div>
    </div>
  );
};

export { SidebarMenu };
