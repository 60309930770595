import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

export const EmptyInterviews = () => {
  return (
    <>
      <div className="mx-auto w-full">
        <div className="card h-md-100" data-bs-theme="light">
          <div className="text-center">
            <img
              src={'/media/pages/calendar_empty.png'}
              width="464px"
              className="w-[464px] mx-auto mb-8"
              alt={'No Interviews'}
            />

            <h3 className="text-h3 font-extrabold">No Jobs ready for your interview!</h3>
            <p className="text-body14">
              Your eager and enthusiastic potential teammates are just a click away from meeting
              you. Just click on the calendar and you'll be all set!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
