import React from 'react';
import { NavLink } from 'react-router-dom';

export const AccountNav: React.FC = () => {
  return (
    <>
      <div className="card-header !min-h-[40px] !p-0 !m-0 !mt-2">
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-bolder">
          <li className="nav-item h-12">
            <NavLink
              end
              to={`/account`}
              className="nav-link text-active-dark text-hover-dark me-6"
            >
              <span className="text-body15">Account</span>
            </NavLink>
          </li>
          <li className="nav-item h-12">
            <NavLink
              end
              to={`/account/email_notifications`}
              className="nav-link text-active-dark text-hover-dark me-6"
            >
              <span className="text-body15">Emails Notifications</span>
            </NavLink>
          </li>
          <li className="nav-item h-12">
            <NavLink
              end
              to={`/account/password`}
              className="nav-link text-active-dark text-hover-dark me-6"
            >
              <span className="text-body15">Password Update</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};
