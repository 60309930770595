/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useAuth } from '../../../../app/modules/auth';
import { Link, useLocation } from 'react-router-dom';

export function CandidateHeaderNotice() {
  const { currentUser } = useAuth();
  const location = useLocation();

  if (
    !currentUser ||
    currentUser.user_type !== 'candidate' ||
    currentUser.profile_status.complete ||
    !location.pathname.includes('/candidate/welcome')
  ) {
    return <></>;
  }

  return (
    <div id={'distro_app_header_candidate_container'} className={'card bg-semi-primary br-0'}>
      <div className={'card-body app-container text-center p-5'}>
        <Link
          to={'/candidate/profile'}
          className={'fw-bolder underline fs-6 text-center text-white m-0 p-0'}
        >
          {/*                                        <span className="fa-stack fa-1x">*/}
          {/*  <i className="fa-solid fa-square fa-stack-2x"></i>*/}
          {/*<i className="fa-solid fa-stack-1x fa-inverse fa-triangle-exclamation"/>*/}
          {/*</span>*/}
          {currentUser.first_name}, complete your profile to boost your chances of being matched to
          a job
          <span className={'fs-5'} aria-hidden="true">
            →
          </span>
        </Link>
      </div>
    </div>
  );
}
