import React, { FC } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { AcceptInvitation } from './AcceptInvitation';

const InvitationAcceptPage: FC = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/:token"
          element={
            <>
              <AcceptInvitation />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default InvitationAcceptPage;
