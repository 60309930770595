/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_theme/helpers';
import { DevTools } from '../../../_theme/layout/components/DevTools';

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.height = '100%';
    }
    return () => {
      if (root) {
        root.style.height = 'auto';
      }
    };
  }, []);

  return (
    <div className="mh-s  d-flex flex-column flex-lg-row flex-column-fluid vh-100 min-vh-100">
      <div className="d-flex flex-column flex-lg-row-fluid w-lg-7 p-10 order-1 order-lg-1">
        <div className="d-flex flex-center flex-column flex-lg-row-fluid">
          <div className="w-lg-500px ps-10 pe-10">
            <Outlet />
          </div>
        </div>
      </div>

      <div
        className="min-vh-100 d-flex flex-lg-row-fluid w-lg-3 bgi-size-cover bgi-position-center order-1 order-lg-1"
        style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg-8.jpg')})` }}
      >
        {/* begin::Content */}
        <div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
          {/* begin::Logo */}
          <Link to="/" className="mb-0">
            <img alt="Logo" src={toAbsoluteUrl('/media/logos/logo.svg')} className="h-25px" />
          </Link>
          {/* end::Logo */}

          {/* begin::Image */}
          {/*<img*/}
          {/*  className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'*/}
          {/*  src={toAbsoluteUrl('/media/misc/auth-screens.png')}*/}
          {/*  alt=''*/}
          {/*/>*/}
          {/* end::Image */}

          {/* begin::Title */}
          {/*<h1 className='text-white fs-2qx fw-bolder text-center mb-7'>*/}
          {/*  Fast, Efficient and Productive*/}
          {/*</h1>*/}
          {/*/!* end::Title *!/*/}

          {/* begin::Text */}
          {/*<div className='text-white fs-base text-center mt-11'>*/}
          {/* Customer Portal Access{' '}*/}
          {/*</div>*/}
          {/* end::Text */}
        </div>
      </div>
    </div>
  );
};

export { AuthLayout };
