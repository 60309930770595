import { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { requestPassword } from '../core/_requests';
import toast from 'react-hot-toast';
import { AnimatedButton } from '../../../../_theme/helpers/components/AnimatedButton';

const initialValues = {
  email: '',
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
});

export function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      requestPassword(values.email)
        .then(({ data: { result } }) => {
          setHasErrors(false);
          setLoading(false);
          setSubmitting(false);
          toast.success('A password reset notification was emailed to you');
          navigate('/auth');
        })
        .catch(() => {
          setHasErrors(true);
          setLoading(false);
          setSubmitting(false);
          setStatus('The login detail is incorrect');
          toast.error('Sorry, an error occurred. Please try again');
        })
        .finally(() => {
          formik.setValues({ email: '' });
          formik.setTouched({ email: false });
        });
    },
  });

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="distro_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10">
        <h1 className="text-dark fw-bolder mb-3">Reset Password</h1>

        <div className="text-gray-500 fw-semibold fs-6">
          We will send you a link to reset your account password
        </div>
      </div>

      {hasErrors === true && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">
            Sorry, an error occurred. Please try again
          </div>
        </div>
      )}

      <div className="fv-row mb-8">
        <label className="form-label fs-6">Email</label>
        <input
          type="email"
          autoComplete="off"
          {...formik.getFieldProps('email')}
          className={clsx('form-control bg-transparent')}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className="d-grid">
        <AnimatedButton
          type="submit"
          id="distro_password_reset_submit"
          disabled={loading}
          processing={loading}
          className="btn btn-lg btn-primary w-100 mb-10"
        >
          <span className="indicator-label">Submit</span>
        </AnimatedButton>
        <div className="text-gray-500 text-center fw-semibold fs-6">
          <Link to="/auth/login" className="link-primary">
            Cancel
          </Link>
        </div>
      </div>
    </form>
  );
}
