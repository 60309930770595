/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { PageTitle } from '../../../_theme/layout/core';

const DashboardPage: FC = () => (
  <>
    <div className="row g-5 gx-xxl-12">
      <div className="col-xxl-12">'Candidate'</div>
    </div>
  </>
);

const DashboardPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardPageWrapper };
