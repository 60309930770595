import React from 'react';
import axios from 'axios';
import { Config } from '../../../../../config';
import { useQuery } from 'react-query';
import { LayoutSplashScreen } from '../../../../../_theme/layout/core';
import toast from 'react-hot-toast';

export default function PayoneerSuccess() {
  const payoneerId = window.location.pathname.split('/').pop();
  const sendPayoneerId = async (): Promise<any> => {
    const response = await axios.post(`${Config.api_url}/candidate/payoneer/success/${payoneerId}`);
    return response.data.value;
  };
  try {
    const { isLoading, isError, isSuccess, data, error, refetch } = useQuery<any>(
      ['candidate.payoneer.success', payoneerId],
      sendPayoneerId
    );
    window.location.href = '/candidate/payment';
  } catch (error: any) {
    // const status = error.response?.data?.status;
    // let message = 'An error occurred, please try again.';
    //
    // if (status) {
    //   message = status;
    // }
    // toast.error(message);
  }
  return (
    <>
      <LayoutSplashScreen />;
    </>
  );
}
