import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../helpers';
import { useLayout } from '../../core';

const SidebarLogo = () => {
  const { config } = useLayout();
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled;
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled;
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : '';
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : '';
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default;
  return (
    <div className={`app-sidebar-logo px-6 ${'!bg-priDark'}`} id="distro_app_sidebar_logo">
      <Link to="/candidate/welcome">
        <img
          alt="Logo"
          height="18px"
          src={toAbsoluteUrl(
            `/media/logos/logo.svg`
          )}
          className="app-sidebar-logo-default h-6 w-full -ml-4 mt-8"
        />
        <img
          alt="Logo"
          height="18px"
          src={toAbsoluteUrl(
            `/media/logos/logo.svg`
          )}
          className="ml-3 app-sidebar-logo-minimize"
        />
      </Link>

      {/*{(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (*/}
      {/*  <div*/}
      {/*    id='distro_app_sidebar_toggle'*/}
      {/*    className={clsx(*/}
      {/*      'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',*/}
      {/*      {active: appSidebarDefaultMinimizeDefault}*/}
      {/*    )}*/}
      {/*    data-distro-toggle='true'*/}
      {/*    data-distro-toggle-state={toggleState}*/}
      {/*    data-distro-toggle-target='body'*/}
      {/*    data-distro-toggle-name={`app-sidebar-${toggleType}`}*/}
      {/*  >*/}
      {/*    <KTSVG path='/media/icons/duotune/arrows/arr079.svg' className='svg-icon-2 rotate-180' />*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

export { SidebarLogo };
