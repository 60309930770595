/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { Config } from '../../../config';
import { LayoutSplashScreen } from '../../../_theme/layout/core';
import { SuperAdminOrganizationsRequestModel } from './Models';
import { useAuth } from '../auth';
import { Link, useNavigate } from 'react-router-dom';
import * as authHelper from '../auth/core/AuthHelpers';
import { AnimatedButton } from '../../../_theme/helpers/components/AnimatedButton';
import toast from 'react-hot-toast';
import Fuse from 'fuse.js';
import debounce from 'lodash.debounce';

interface SuperAdminProps {
  user_id: string;
}

const HIDE_DISTRO_FOR_USERS = ['rebeca', 'luca'];

export function Overview(props: SuperAdminProps) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    logout,
    impersonateOrganization,
    clearAndSetAuth,
    saveAuth,
    setCurrentUser,
    setAccountType,
    currentUser,
  } = useAuth();
  const [impersonatedOrg, setImpersonatedOrg] = useState<{
    id: string | null;
    name: string | null;
  }>({
    id: null,
    name: null,
  });
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);
  const onSearchFilterChange = (event: any) => {
    const value = event.target.value.trim();
    setSearchFilter(value);
  };

  const debouncedChangeHandler = useMemo(() => debounce(onSearchFilterChange, 300), []);
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  const stopImpersonation = useCallback(async () => {
    setProcessing(true);
    try {
      const response = await axios.post(`${Config.api_url}/admin/halt-impersonation`);
      const data = await response.data.value;
      const authModel = {
        access_token: data.authorization.access_token,
        expires_in: data.authorization.expires_in,
        user: data.user,
      };
      saveAuth(undefined);
      setCurrentUser(undefined);
      setAccountType(undefined);
      authHelper.removeAuth();
      saveAuth(authModel);
      setProcessing(false);
      queryClient.clear();
      localStorage.setItem('impersonated_org', '');
      toast.success('Impersonation Cleared');
    } catch (e) {
      logout();
      location.replace(Config.app_url + '/');
    }
  }, []);

  const handleImpersonation = useCallback(async (org_id: string) => {
    setProcessing(true);
    const response = await axios.post(
      `${Config.api_url}/admin/organizations/${org_id}/impersonate`
    );
    const data = await response.data.value;
    const authModel = {
      access_token: data.authorization.access_token,
      expires_in: data.authorization.expires_in,
      user: data.user,
    };
    impersonateOrganization(org_id, authModel);
    setImpersonatedOrg({ name: data.user.organization_name, id: data.user.organization_id });
    setProcessing(false);
    location.replace(Config.app_url + '/');
    localStorage.setItem('impersonated_org', JSON.stringify(data.user.organization_name));
  }, []);

  const fetchOrganizations = async (): Promise<Array<SuperAdminOrganizationsRequestModel>> => {
    const response = await axios.get(`${Config.api_url}/admin/organizations`);
    const data = await response.data.value;
    return data.sort((a: any, b: any) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
  };

  const { isLoading, data } = useQuery(['super.admin.organizations'], fetchOrganizations, {
    select: (jobs: Array<SuperAdminOrganizationsRequestModel>) => {
      if (searchFilter) {
        const fuse = new Fuse(jobs, {
          keys: ['name'],
          threshold: 0.2,
          distance: 400,
        });

        const results = fuse.search<Array<SuperAdminOrganizationsRequestModel>>(searchFilter);
        jobs = results.map((result) => result.item) as any;
      }

      return jobs;
    },
  });

  if (isLoading || !data) {
    return <LayoutSplashScreen />;
  }

  const shouldHideDistro =
    HIDE_DISTRO_FOR_USERS.includes(currentUser?.email?.split('@')?.[0] as string) &&
    currentUser?.email?.includes('@distro.io');

  return (
    <div className={'container-sm mx-auto p-2 mt-5'} style={{ width: 700 }}>
      <div className={'mt-5 mb-5'}>
        <Link to={'/'} className={'fa-icon me-3 btn btn-sm btn-outline text-primary'}>
          <i className="fa-solid fa-house fa-primary text-primary" />
          Home
        </Link>
        <AnimatedButton
          className={'btn btn-sm btn-info'}
          disabled={processing}
          processing={processing}
          onClick={stopImpersonation}
        >
          Stop Impersonation
        </AnimatedButton>
      </div>
      <div className={'mt-5 mb-5'}>
        <input
          type={'text'}
          className={'form-control form-control-sm'}
          placeholder={'Search by Name'}
          onChange={debouncedChangeHandler}
        />
      </div>
      <div className={'card  border-1 border-gray- '}>
        <div className={'card-body'}>
          <table className={'table table-row-dashed align-middle gs-0 gy-3 my-0'}>
            <thead>
              <tr className="fs-7 fw-bold fw-bolder border-bottom-0">
                <th className="p-0 pb-3  text-start">Name</th>
                <th className="p-0 pb-3 text-start">Candidates</th>
                <th className="p-0 pb-3 text-start">Hires</th>
                <th className="p-0 pb-3 ">Jobs</th>
                <th className="p-0 pb-3 ">Roles</th>
                <th className="p-0 pb-3 "></th>
              </tr>
            </thead>
            <tbody>
              {data
                ?.filter((item) => {
                  if (shouldHideDistro) {
                    return item?.name?.toLowerCase()?.includes('distro') === false;
                  }
                  return true;
                })
                .map((d) => {
                  return (
                    <tr key={d.id}>
                      <td>{d.name}</td>
                      <td>{d.candidates}</td>
                      <td>{d.hires}</td>
                      <td>{d.jobs}</td>
                      <td>{d.active_roles}</td>
                      <td className={'text-end'}>
                        <button
                          onClick={() => handleImpersonation(d.id)}
                          type={'button'}
                          disabled={processing}
                          className={'btn btn-sm  btn-bg-light btn-active-color-primary'}
                        >
                          Impersonate
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
