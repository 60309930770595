/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FormEvent, useState } from 'react';
import { AccountNav } from './components/AccountNav';
import { useAuth } from '../auth';
import { EmailNotificationsResponse } from './Models';
import axios from 'axios';
import { Config } from '../../../config';
import { useQuery } from 'react-query';
import { LayoutSplashScreen } from '../../../_theme/layout/core';
import toast from 'react-hot-toast';

export function EmailNotificationSettings() {
  const Auth = useAuth();
  const [processing, setProcessing] = useState(false);

  const onChange = async (e: FormEvent<HTMLInputElement>, id: string) => {
    setProcessing(true);
    const checked = e.currentTarget.checked;
    await axios.post(`${Config.api_url}/me/email_notifications`, {
      notification_id: id,
      value: checked,
    });
    await refetch();
    toast.success('Email Notifications Updated!');
    setProcessing(false);
  };

  if (!Auth.currentUser) {
    return <></>;
  }

  const fetchNotifications = async (): Promise<Array<EmailNotificationsResponse>> => {
    const response = await axios.get(`${Config.api_url}/me/email_notifications`);
    return await response.data.value;
  };

  const { data, isLoading, refetch } = useQuery(
    ['profile.notifications', Auth.currentUser.email],
    fetchNotifications
  );

  if (!data || isLoading) {
    return <LayoutSplashScreen />;
  }

  // if (data.length === 0) {
  //   return <></>;
  // }

  return (
    <>
      <div className="d-flex flex-colun flex-lg-row">
        <div className="flex-lg-row-fluid order-lg-1 mb-10 mb-lg-0">
          <div className="card mt-0 mb-7">
            <AccountNav />
          </div>

          {Boolean(data.length) && (
            <div className="card">
              <div className={'card-header'}>
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Email Notification Settings</h3>
                </div>
              </div>
              <div className="card-body">
                {data.map((dataItem: EmailNotificationsResponse, i, { length }) => (
                  <div className={'email-item'} key={i}>
                    <label className="form-check form-check-custom form-check-solid align-items-start">
                      <input
                        checked={dataItem.active}
                        disabled={processing}
                        className="form-check-input me-3"
                        name={dataItem.id}
                        type="checkbox"
                        onChange={(e) => onChange(e, dataItem.id)}
                      />
                      <span className="form-check-label d-flex flex-column align-items-start">
                        <span className="fw-bold fs-5 mb-0">{dataItem.friendly_name}</span>
                        <span className="text-muted fs-6">
                          Receive updates about product enhancements and recommendations.
                        </span>
                      </span>
                    </label>
                    {length - 1 !== i && <div className="separator separator-dashed my-6"></div>}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
