import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_theme/layout/MasterLayout';
import { DashboardPageWrapper } from '../modules/candidate/DashboardPageWrapper';
import PayoneerSuccess from '../modules/candidate/payment/components/PayoneerSuccess';
import { lazyRetry } from '../util/lazyreload';
import Interviews from '../modules/candidate/interviews/Interviews';
import InterviewsHistory from '../modules/candidate/interviews/InterviewsHistory';
import WelcomePage from '../modules/candidate/welcome/WelcomePage';

const ProfilePage = lazy(() => lazyRetry(() => import('../modules/candidate/profile/ProfilePage')));
const PaymentPage = lazy(() => lazyRetry(() => import('../modules/candidate/payment/PaymentPage')));
const WiseForm = lazy(() =>
  lazyRetry(() => import('../modules/candidate/payment/components/WiseForm'))
);
const Jobs = lazy(() => lazyRetry(() => import('../modules/candidate/jobs/JobsPage')));
const ViewJob = lazy(() => lazyRetry(() => import('../modules/candidate/jobs/ViewJob')));
const InvitationPage = lazy(() =>
  lazyRetry(() => import('../modules/candidate/invitations/InvitationPage'))
);
const CalendarPage = lazy(() =>
  lazyRetry(() => import('../modules/candidate/calendar/CalendarPage'))
);

const CandidateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="welcome/*" element={<WelcomePage />} />
        <Route path="payment/*" element={<PaymentPage />} />
        <Route path="dashboard" element={<DashboardPageWrapper />} />
        <Route path="profile/*" element={<ProfilePage />} />
        <Route path="wise" element={<WiseForm />} />
        <Route path="payoneer/success/*" element={<PayoneerSuccess />} />
        <Route path="jobs/*" element={<Jobs />} />
        <Route path="invite" element={<InvitationPage />} />
        <Route path="interviews" element={<Interviews />} />
        <Route path="interviews/history" element={<InterviewsHistory />} />
        <Route path="calendar" element={<CalendarPage />} />
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

export { CandidateRoutes };
