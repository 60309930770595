import { getScrollTop, throttle } from '../_utils';

export class FixedToolbarComponent {
  static offset = 130;

  constructor() {
    this._handlers();
  }

  public static bootstrap = (): void => {
    new FixedToolbarComponent();
  };

  private _handlers = (): void => {
    let timer: number;
    window.addEventListener('scroll', () => {
      throttle(timer, () => {
        this._scroll();
      });
    });
  };

  /**
   * This is frustrating because sometimes a hard refresh is required to get this to be recognized.
   * I think it's because of how the elements are cached at dom load... so for now I'm getting them as you scroll.
   */
  private _scroll = (): void => {
    const offset = FixedToolbarComponent.offset;
    const pos = getScrollTop();
    const toolbar = this.getToolbarElement();
    const sidebar = this.getSidebarElement();
    const toolbarButtons = this.getToolbarButtonsElement();
    const breadcrumbs = this.getToolbarBreadcrumbs();
    // if (pos > offset && toolbar && toolbarButtons && toolbarButtons.childElementCount !== 0) {
    //   toolbar.style.left = sidebar.offsetWidth + 'px';
    //   toolbar.classList.add('fixed-top', 'animate__animated', 'animate__slideInDown');
    //   if (breadcrumbs) {
    //     breadcrumbs.classList.remove('d-none');
    //     breadcrumbs.classList.add('d-flex');
    //   }
    //   document.body.style.paddingTop = toolbar.offsetHeight + 'px';
    // } else {
    //   if (toolbar) {
    //     toolbar.classList.remove('fixed-top', 'animate__animated', 'animate__slideInDown');
    //     toolbar.removeAttribute('style');
    //   }
    //   if (breadcrumbs) {
    //     breadcrumbs.classList.add('d-none');
    //     breadcrumbs.classList.remove('d-flex');
    //   }
    //   document.body.style.paddingTop = '0';
    // }
  };

  private getToolbarElement() {
    return document.getElementById('distro_app_toolbar') as HTMLElement;
  }

  private getSidebarElement() {
    return document.getElementById('distro_app_sidebar') as HTMLElement;
  }

  private getToolbarButtonsElement() {
    return document.getElementById('distro_app_toolbar_buttons') as HTMLElement;
  }

  private getToolbarPageTitle() {
    return document.getElementById('distro_page_title') as HTMLElement;
  }

  private getToolbarBreadcrumbs() {
    return this.getToolbarPageTitle()?.querySelector('.breadcrumb');
  }
}
