import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  LayoutSplashScreen,
  LayoutToolbarButtons,
  PageLink,
  PageTitle,
} from '../../../../_theme/layout/core';
import { Link } from 'react-router-dom';
import { useAuth } from '../../auth';
import { CandidateProfileModel } from '../profile/Models';
import { Config } from '../../../../config';
import axios from 'axios';
import { useQuery } from 'react-query';
import { PaymentMethod } from '../payment/Models';

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/',
    isSeparator: false,
    isActive: true,
  },
];

const completedStyles = 'white-bg filter-none opacity-100 pointer-events-auto';

const WelcomePage: FC = () => {
  const [step, setStep] = useState(0);

  const { currentUser } = useAuth();

  const fetchProfile = async (): Promise<CandidateProfileModel> => {
    const response = await axios.get(`${Config.api_url}/candidate/profile`);
    return await response.data.value;
  };

  const { isLoading, data: profileData } = useQuery(
    ['candidate.profile', currentUser?.id],
    fetchProfile
  );

  const fetchBankAccount = async (): Promise<Array<PaymentMethod>> => {
    const response = await axios.get(`${Config.api_url}/candidate/payee`);
    return await response.data.value;
  };

  const { data: paymentData } = useQuery(['candidate.payee.methods'], fetchBankAccount);

  const fetchJobs = async () => {
    const response = await axios.get(`${Config.api_url}/candidate/jobs`);
    return await response.data.value;
  };

  const { data: jobsData } = useQuery(['customer.jobs', currentUser?.id], fetchJobs);

  useEffect(() => {
    if (profileData?.profile_status?.complete && !profileData.avatar_is_default) {
      setStep(1);
      // TODO VETIA: will need to change this check when VetAI is implemented
      if (profileData?.video?.id) {
        setStep(2);
        if (jobsData && jobsData?.some((job: any) => job?.lastest_stage === 'hired')) {
          setStep(3);
          if (paymentData && paymentData?.length > 0) {
            setStep(4);
          }
        }
      }
    }
  }, [profileData, jobsData, paymentData]);

  const completion = useMemo(() => {
    let currentCompletion = 0;

    if (currentUser?.profile_status?.completion_status?.bio_complete) {
      currentCompletion += 30;
    }
    if (currentUser?.profile_status?.completion_status?.skills_complete) {
      currentCompletion += 20;
    }
    if (currentUser?.profile_status?.completion_status?.unique_avatar) {
      currentCompletion += 10;
    }
    if (currentUser?.profile_status?.completion_status?.video_resume) {
      currentCompletion += 30;
    }
    if (currentUser?.profile_status?.completion_status?.years_professional_experience_complete) {
      currentCompletion += 10;
    }

    return currentCompletion;
  }, [currentUser]);

  if (isLoading || !profileData) {
    return <LayoutSplashScreen />;
  }

  return (
    <>
      <LayoutToolbarButtons>
        <div className="flex flex-col">
          <h1 className="text-h1 font-extrabold">Profile Update</h1>
          <p className="text-body14 font-medium text-pri">{completion}% Complete</p>
        </div>
      </LayoutToolbarButtons>
      <div className={'card-body'}>
        <div className={'text-center'}>
          <h1 className={'text-h1 font-extrabold'}>Welcome to Distro!</h1>
          <h5 className={'text-darkGray text-body14'}>
            In order to get matched to companies hiring for you skills, complete the following steps
            below.
          </h5>
          <img
            src={`/media/pages/welcome/progress_${step}.png`}
            className="w-[892px] mx-auto my-[16px]"
            alt="Profile Progress"
          />
        </div>

        <div className="flex justify-center w-full gap-[24px]">
          <div
            className={`m-0 !w-[260px] !h-[520px] 
            border ${step === 0 ? '!border-pri' : 'border-lightGray'}
            ${step === 0 ? 'bg-priLight00' : ''}
            px-[30px] py-[20px] 
            flex flex-col justify-between
            rounded-[25px]
            ${step === 0 ? 'filter-none' : 'pointer-events-none filter grayscale opacity-50'}
            ${step > 0 ? completedStyles : ''}`}
          >
            <img
              src={`/media/pages/welcome/video.png`}
              className={`w-[80px] mx-auto ${step >= 0 ? 'filter-none' : 'filter grayscale'}`}
              alt="Step process"
            />

            <span className="text-center">
              <h2 className="text-body15 font-bold">Setup Account Profile</h2>
              <p className={'text-center text-body12 font-normal'}>
                Completing Your profile opens doors to the right job opportunities! By detailing
                your skills and experiences, you help us find jobs that fit you perfectly. This not
                only saves you search time but also boosts your chances of finding a job that suits
                your strengths. Fill out your profile, and let's find the best matches for you!
              </p>
            </span>

            <span>
              {step <= 0 ? (
                <Link
                  to={'/candidate/profile/edit'}
                  className="btn main-button main-gradient !text-white w-full"
                >
                  Complete your profile
                </Link>
              ) : (
                <span className="block text-body12 font-12 text-lightGray2 text-center">
                  <i className="bi bi-check-circle-fill !text-green mr-2"></i>
                  Profile Completed
                </span>
              )}
            </span>
          </div>

          <div
            className={`m-0 !w-[260px] !h-[520px] 
            border ${step === 1 ? '!border-pri' : 'border-lightGray'}
            ${step === 1 ? 'bg-priLight00' : 'bg-lightGray'}
            px-[30px] py-[20px] 
            flex flex-col justify-between
            rounded-[25px]
            ${step === 1 ? 'filter-none' : 'pointer-events-none filter grayscale opacity-50'}
            ${step > 1 ? completedStyles : ''}
            `}
          >
            <img
              src={`/media/pages/welcome/profile.png`}
              className={`w-[80px] mx-auto ${step >= 1 ? 'filter-none' : 'filter grayscale'}`}
              alt="Step process"
            />

            <span className="text-center">
              <h2>Record Introduction Interview</h2>
              <p className={'text-center text-body12'}>
                Sharing your skills and experiences helps us match you with the best job
                opportunities! Recording your introduction interview is a crucial step—it not only
                saves you time but also increases your chances of finding a role that fits your
                strengths. Our team will review your video to ensure we find the perfect match for
                you!
              </p>
            </span>

            <span>
              {step <= 1 ? (
                <Link
                  to={'/candidate/interviews'}
                  className="btn main-button main-gradient !text-white w-full"
                >
                  Record Interview
                </Link>
              ) : (
                <span className="block text-body12 font-12 text-lightGray2 text-center">
                  <i className="bi bi-check-circle-fill !text-green mr-2"></i>
                  Interview Completed
                </span>
              )}
            </span>
          </div>

          <div
            className={`m-0 !w-[260px] !h-[520px] 
            border ${step === 2 ? '!border-pri' : 'border-lightGray'}
            ${step === 2 ? 'bg-priLight00' : 'bg-lightGray'}
            px-[30px] py-[20px] 
            flex flex-col justify-between
            rounded-[25px]
            ${step === 2 ? 'filter-none' : 'pointer-events-none filter grayscale opacity-50'}
            ${step > 2 ? completedStyles : ''}
            `}
          >
            <img
              src={`/media/pages/welcome/hired.png`}
              className={`w-[80px] mx-auto ${step >= 2 ? 'filter-none' : 'filter grayscale'}`}
              alt="Step process"
            />

            <span className="text-center">
              <h2>Get Hired!</h2>
              <p className={'text-center text-body12'}>
                All done!! Sit back, relax and allow our team to match you with opportunities at
                premier companies. If you ever have any questions, please reach out by clicking on
                the 💬 symbol in the bottom right hand side of the screen, or email us anytime:{' '}
                <Link className="text-pri" to="mailto:support@distro.io">
                  support@distro.io
                </Link>
              </p>
            </span>

            <span>
              {step <= 2 ? (
                <Link
                  to="/candidate/jobs"
                  className="btn main-button main-gradient !text-white w-full"
                >
                  Get Hired
                </Link>
              ) : (
                <span className="block text-body12 font-12 text-lightGray2 text-center">
                  <i className="bi bi-check-circle-fill !text-green mr-2"></i>
                  You're hired!
                </span>
              )}
            </span>
          </div>

          <div
            className={`m-0 !w-[260px] !h-[520px] 
            border ${step === 3 ? '!border-pri' : 'border-lightGray'}
            ${step === 3 ? 'bg-priLight00' : 'bg-lightGray'}
            px-[30px] py-[20px] 
            flex flex-col justify-between
            rounded-[25px]
            ${step === 3 ? 'filter-none' : 'pointer-events-none filter grayscale opacity-50'}
            ${step > 3 ? completedStyles : ''}
            `}
          >
            <img
              src={`/media/pages/welcome/payment.png`}
              className={`w-[80px] mx-auto ${step >= 3 ? 'filter-none' : 'filter grayscale'}`}
              alt="Step process"
            />

            <span className="text-center">
              <h2>Add Payment Information</h2>
              <p className={'text-center text-body12'}>
                Making sure you get paid on time is our main goal. By providing your payment
                details, you help smooth out the payment process, avoiding any delays. It's a
                direct, safe way to ensure you receive your earnings as expected. Please fill out
                your payment information today to ensure prompt and accurate payment.
              </p>
            </span>

            <span>
              {step <= 3 ? (
                <Link
                  to="/candidate/payment"
                  className="btn main-button main-gradient !text-white w-full"
                >
                  Add Payment Method
                </Link>
              ) : (
                <span className="block text-body12 font-12 text-lightGray2 text-center">
                  <i className="bi bi-check-circle-fill !text-green mr-2"></i>
                  Payment Method Added
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomePage;
