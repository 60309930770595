/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../auth';
import axios from 'axios';
import { Config } from '../../../../config';
import { useQuery } from 'react-query';
import { JobStage } from '../../../components/JobStages';
import {
  LayoutSplashScreen,
  LayoutToolbarButtons,
  PageLink,
  PageTitle,
} from '../../../../_theme/layout/core';
import { Scheduler } from '../../../components/Scheduler';
import { InterviewsNav } from './components/InterviewsNav';
import { EmptyInterviews } from './components/EmptyInterviews';
import { Link } from 'react-router-dom';

export default function Interviews() {
  const { currentUser } = useAuth();

  const fetchJobs = async () => {
    const response = await axios.get(`${Config.api_url}/candidate/interview-jobs`);
    return await response.data.value;
  };

  const { isLoading, data } = useQuery(['candidate.interview.jobs', currentUser?.id], fetchJobs);

  if (isLoading || !data) {
    return <LayoutSplashScreen />;
  }

  return (
    <>
      <LayoutToolbarButtons>
        <div className="flex flex-col">
          <h1 className="text-h1 font-extrabold">Interviews</h1>
          <Link className="text-body14 font-medium text-lightGray2" to={`/candidate/welcome`}>
            Dashboard
          </Link>
        </div>
      </LayoutToolbarButtons>
      <div className="card mb-7">
        <InterviewsNav />
      </div>

      {data?.length === 0 ? (
        <EmptyInterviews />
      ) : (
        <div className="m-2">
          <table className={'table align-middle gs-0 gy-4 !rounded-[10px] !px-4'}>
            <thead className="bg-[#F8FAFF] border border-[#E7ECFD] !rounded-[10px] ">
              <tr className="px-4">
                <th className={'text-body12 font-semibold'}>Company</th>
                <th className={'text-body12 font-semibold'}>Job Title</th>
                <th className={'text-body12 font-semibold'}>Date</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((v: any) => (
                <>
                  <tr key={v.id}>
                    <td>{v.organization_name}</td>
                    <td>{v.job_title}</td>
                    <td>{v.date}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
