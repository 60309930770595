import React, { FC, lazy } from 'react';
import { PageLink, PageTitle } from '../../../_theme/layout/core';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useAuth } from '../auth';
import { MasterLayout } from '../../../_theme/layout/MasterLayout';
import { lazyRetry } from '../../util/lazyreload';

const Overview = lazy(() => lazyRetry(() => import('./Overview')));

const NotificationsPage: FC = () => {
  const auth = useAuth();
  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Overview',
      path: '/notifications',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route element={<Outlet />}>
          <Route
            path="/"
            element={
              <>
                <PageTitle breadcrumbs={breadCrumbs}>Notifications</PageTitle>
                <Overview user_id={auth.currentUser?.id as string} />
              </>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default NotificationsPage;
