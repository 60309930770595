/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Config } from '../../../config';
import toast from 'react-hot-toast';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { LayoutSplashScreen } from '../../../_theme/layout/core';
import { useAuth } from '../auth';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { AnimatedButton } from '../../../_theme/helpers/components/AnimatedButton';
YupPassword(Yup);

const AcceptInvitation = () => {
  const { logout } = useAuth();
  const [org, setOrg] = useState<any>({});
  const [checkingToken, setCheckingToken] = useState(true);
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [loading, setLoading] = useState(false);

  const { token } = useParams();
  const navigate = useNavigate();

  const initialValues = {
    firstname: '',
    lastname: '',
    password: '',
  };

  const validationSchema: any = {
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    password: Yup.string()
      .required('New Password is required')
      .min(
        8,
        'New Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special'
      )
      .minLowercase(1, 'New Password must contain at least 1 lower case letter')
      .minUppercase(1, 'New Password must contain at least 1 upper case letter')
      .minNumbers(1, 'New Password must contain at least 1 number')
      .minSymbols(1, 'New Password must contain at least 1 special character'),
  };

  const registrationSchema = Yup.object().shape(validationSchema);
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const response = await axios.post(`${Config.api_url}/invitation/${token}/accept`, {
          first_name: values.firstname,
          last_name: values.lastname,
          password: values.password,
        });
        await logout();
        toast.success(`Invitation Accepted.  Please Login.`);
        navigate('/auth');
      } catch (error: any) {
        const status = error.response?.status;
        const errors = error.response?.data?.errors;
        let message = 'Registration failed, please try again';
        if (status === 422 && errors) {
          message = '';
          Object.values(errors).forEach((k: any) => {
            k.forEach((v: any) => {
              message += `- ${v}<br/>`;
            });
          });
        }
        setStatus(message);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const handlePasswordToggle = (e: any) => {
    setPasswordToggle(!passwordToggle);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(`${Config.api_url}/invitation/${token}`);
        setCheckingToken(false);
        setOrg(response.data.value);
      } catch (e) {
        toast.error('Invalid Invitation Token');
        navigate('/');
      }
    };
    fetch();
  }, [token]);

  if (checkingToken) {
    return <LayoutSplashScreen />;
  }

  return (
    <div className="d-flex flex-column flex-lg-row flex-column-fluid h-100">
      <div className="d-flex flex-column flex-lg-row-fluid w-lg-7 p-10 order-2 order-lg-1">
        <div className="d-flex flex-center flex-column flex-lg-row-fluid">
          <div className="w-lg-500px p-10">
            <form
              className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
              noValidate
              id="distro_login_signup_form"
              onSubmit={formik.handleSubmit}
            >
              <div className="text-center mb-11">
                <h1 className="text-dark fw-bolder mb-3">Accept Invitation</h1>
                {org.organization?.name && <p>Join {org.organization.name} in using Distro!</p>}
              </div>

              {formik.status && (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">{formik.status}</div>
                </div>
              )}

              <div className="fv-row mb-8">
                <div className="row">
                  <div className="col-lg-6">
                    <label className="form-label fs-6">First Name</label>
                    <input
                      type="text"
                      autoComplete="off"
                      {...formik.getFieldProps('firstname')}
                      className={clsx('form-control bg-transparent')}
                    />
                    {formik.touched.firstname && formik.errors.firstname && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.firstname}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6">
                    <label className="form-label fs-6">Last name</label>
                    <input
                      type="text"
                      autoComplete="off"
                      {...formik.getFieldProps('lastname')}
                      className={clsx('form-control bg-transparent')}
                    />
                    {formik.touched.lastname && formik.errors.lastname && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.lastname}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="fv-row mb-8" data-distro-password-meter="true">
                <div className="mb-1">
                  <label className="form-label fs-6">Password</label>
                  <div className="position-relative mb-3">
                    <div className="input-group mb-3">
                      <input
                        type={passwordToggle ? 'input' : 'password'}
                        autoComplete="off"
                        {...formik.getFieldProps('password')}
                        className={clsx('form-control bg-transparent')}
                      />
                      <button
                        onClick={handlePasswordToggle}
                        className="btn btn-outline"
                        type="button"
                        id="button-addon2"
                      >
                        <i className={`fa-solid ${passwordToggle ? 'fa-eye-slash' : 'fa-eye'}`} />
                      </button>
                    </div>
                    {formik.touched.password && formik.errors.password && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.password}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="text-muted text-center mb-8">
                <p>By creating an account, you agree to our terms and conditions.</p>
              </div>

              <div className="text-center">
                <AnimatedButton
                  type="submit"
                  id="distro_sign_up_submit"
                  className="btn btn-lg btn-primary w-100 mb-5"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  Create Account
                </AnimatedButton>
                <Link to="/">Cancel</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AcceptInvitation };
