import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { verifyEmail } from '../core/_requests';
import { LayoutSplashScreen } from '../../../../_theme/layout/core';
import toast from 'react-hot-toast';
import { useEffect } from 'react';

export function VerifyEmail() {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();

  const checkEmail = async () => {
    try {
      await verifyEmail(token as string);
      toast.success('Email Verified!');
    } catch (e) {
      toast.error('Token invalid, please try again.');
    }

    navigate('/auth/login');
  };

  useEffect(() => {
    checkEmail();
  }, [token]);

  return <LayoutSplashScreen />;
}
