export interface Options {
  value: string;
  label: string;
  options?: Array<Options> | null;
}

const flattenJobRoles = (arr: Array<Options>) =>
  arr.flatMap(({ options, ...rest }) => {
    if (!options) {
      return rest;
    }

    return options?.map((o: Options) => ({
      ...rest,
      ...o,
    }));
  });

export const FlattenedJobRoles = (list?: any): Array<Options> => flattenJobRoles(list ? list : []);

export const findJobRoleByValue = (value: string, list?: any) =>
  FlattenedJobRoles(list ? list : []).find((o) => o.value === value);
export const findJobLabelByValue = (value: string, list?: any) =>
  findJobRoleByValue(value, list)?.label;
export const findJobRoleByLabel = (label: string, list?: any) =>
  FlattenedJobRoles(list ? list : []).find((o) => o.label === label);
