import React from 'react';
import { Navigate, useLocation } from 'react-router';
import { useAuth } from '../../app/modules/auth';

type Props = {
  children: JSX.Element;
};

export const PrivateRoute: React.FC<Props> = ({ children }) => {
  const { currentUser } = useAuth();
  let location = useLocation();

  if (!currentUser) {
    return <Navigate to="/auth" state={{ from: location }} />;
  }

  return children;
};
