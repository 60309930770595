import React, { FC } from 'react';
import { WithChildren } from '../react18MigrationHelpers';
import clsx from 'clsx';

interface AnimatedButtonProps {
  className?: string;
  processing?: boolean;
  disabled?: boolean;
  id?: string;
  size?: 'sm' | 'md';
  onClick?: (e: any) => void;
  type?: 'button' | 'submit';
}

const AnimatedButton: FC<AnimatedButtonProps & WithChildren> = (props) => {
  const { processing, ...rest } = props;
  const setSize = () => {
    return `spinner-border-${props.size ?? 'sm'}`;
  };
  return (
    <button {...rest}>
      {props.children}
      {props.processing && (
        <span className="indicator-progress ms-1" style={{ display: 'inline-block' }}>
          <span className={clsx(`spinner-border align-middle ${setSize()}`)}></span>
        </span>
      )}
    </button>
  );
};

export { AnimatedButton };
