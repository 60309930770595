import { AuthModel } from './_models';
import { AxiosError, AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';
import jwt_decode from 'jwt-decode';
import toast from 'react-hot-toast';

interface JWTDecoded {
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  nbf: number;
  prv: string;
  email: string; // email
  sub: string; // user id
  meta: {
    org_id: string; // organization_id
    user_type: string; // 'customer', 'candidate'
  };
}

const AUTH_LOCAL_STORAGE_KEY = 'distro-auth';
const AUTH_ACCOUNT_TYPE_STORAGE_KEY = 'distro-account-type';

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }
  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      const decoded = jwt_decode<JWTDecoded>(auth.access_token);
      // If it has expired, return void;
      // TODO: Try and get a refresh token.
      if (Date.now() >= decoded.exp * 1000) {
        return;
      }

      return auth;
    }
  } catch (error) {
    Sentry.captureException(error);
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    Sentry.captureException(error);
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
  }
};

const clearAndSetAuth = (auth: AuthModel) => {
  removeAuth();
  setAuth(auth);
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    Sentry.captureException(error);
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error);
  }
};

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json';
  axios.defaults.withCredentials = true;
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = getAuth();
      if (auth && auth.access_token) {
        config.headers.Authorization = `Bearer ${auth.access_token}`;
      }

      return config;
    },
    (error: AxiosError) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      const status = error?.response?.status;
      // If there is a 401 response from an endpoint, redirect them to authenticate in.
      // TODO: Make sure backend 401's ONLY occur for auth reasons, and not permissions.
      if (status === 401 || status === 423) {
        if (window.location.href.indexOf('auth') == -1) {
          console.debug(`Redirecting due to: ${status}.`);
          if (status === 423) {
            toast.error('Email Address Not Verified.');
          }

          window.location.replace('/auth');
        }
      }

      return Promise.reject(error);
    }
  );
}

export {
  getAuth,
  setAuth,
  removeAuth,
  clearAndSetAuth,
  AUTH_LOCAL_STORAGE_KEY,
  AUTH_ACCOUNT_TYPE_STORAGE_KEY,
};
