export const Config = {
  name: process.env.REACT_APP_APP_NAME,
  env: process.env.REACT_APP_NODE_ENV,
  isLocal: process.env.REACT_APP_NODE_ENV === ('local' as string),
  app_url: process.env.REACT_APP_PUBLIC_URL as string,
  api_url: process.env.REACT_APP_PLATFORM_URL,
  services: {
    pusher: {
      id: process.env.REACT_APP_PUSHER_ID as string,
      key: process.env.REACT_APP_PUSHER_KEY as string,
      secret: process.env.REACT_APP_PUSHER_SECRET as string,
      port: parseInt(process.env.REACT_APP_PUSHER_PORT as string),
      tls: process.env.REACT_APP_PUSHER_TLS as string,
      host: process.env.REACT_APP_PUSHER_HOST as string,
      cluster: process.env.REACT_APP_PUSHER_CLUSTER as string,
    },
    sentry: {
      key: process.env.REACT_APP_SENTRY_KEY,
    },
    aws: {
      bucket: process.env.REACT_APP_BUCKET_NAME as string,
      region: process.env.REACT_APP_REGION as string,
      id: process.env.REACT_APP_AWS_ID as string,
      key: process.env.REACT_APP_AWS_KEY as string,
    },
    stripe: {
      key: process.env.REACT_APP_STRIPE_KEY as string,
    },
    posthog: {
      key: process.env.REACT_APP_PUBLIC_POSTHOG_KEY as string,
      host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST as string,
    },
  },
};
