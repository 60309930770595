import { Navigate } from 'react-router-dom';
import { useAuth } from '../modules/auth';
import React from 'react';

export const OwnerAdminRoute = ({ children }: any) => {
  const { roles, logout } = useAuth();
  if (roles?.includes('owner') || roles?.includes('admin')) {
    return children;
  }
  logout();
  return <Navigate to="/auth" />;
};
