/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { register } from '../core/_requests';
import { Link, useSearchParams } from 'react-router-dom';
import { useAuth } from '../core/Auth';
import parse from 'html-react-parser';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { AnimatedButton } from '../../../../_theme/helpers/components/AnimatedButton';
import { FlattenedJobRoles, Options } from '../../../util/jobroles';
import axios from 'axios';
import { Config } from '../../../../config';
import { useQuery } from 'react-query';

YupPassword(Yup);

const initialValues = {
  business_name: '',
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  account_type: 'customer',
  recruiter: null,
  desired_role: '',
  resume: null,
};

export function Registration() {
  const [queryParameters] = useSearchParams();
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [type, setType] = useState<'customer' | 'candidate'>();
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const [registered, setRegistered] = useState(false);
  const [recruiter, setRecruiter] = useState<string | null>(null);
  const [jobRoles, setJobRoles] = useState<Array<Options> | undefined>([]);

  useEffect(() => {
    setRecruiter(queryParameters.get('r'));
  }, []);

  const validationSchema: any = {
    account_type: Yup.string().required(),
    firstname: Yup.string().required('First name is required'),
    email: Yup.string().email('Wrong email format').required('Email is required'),
    lastname: Yup.string().required('Last name is required'),
    password: Yup.string()
      .required('New Password is required')
      .min(
        8,
        'New Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special'
      )
      .minLowercase(1, 'New Password must contain at least 1 lower case letter')
      .minUppercase(1, 'New Password must contain at least 1 upper case letter')
      .minNumbers(1, 'New Password must contain at least 1 number')
      .minSymbols(1, 'New Password must contain at least 1 special character'),
  };

  const fetchJobRoles = async (): Promise<Array<Options>> => {
    const response = await axios.get(`${Config.api_url}/job-roles`);
    return await response.data.value;
  };

  const jobRoleQuery = useQuery(['job.roles', ['registration']], fetchJobRoles);

  useEffect(() => {
    setJobRoles(jobRoleQuery.data);
  }, []);

  if (type == 'customer') {
    validationSchema.business_name = Yup.string().required('Business name is required');
  }

  if (type == 'candidate') {
    validationSchema.desired_role = Yup.string().required('Desired role is required');
    validationSchema.resume = Yup.string().required('Resume is required');
  }

  const registrationSchema = Yup.object().shape(validationSchema);
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await register(
          values.email,
          values.firstname,
          values.lastname,
          values.password,
          values.account_type as any,
          values.business_name,
          values.desired_role,
          values.resume,
          recruiter
        );

        setRegistered(true);
      } catch (error: any) {
        const status = error.response?.status;
        const errors = error.response?.data?.errors;
        let message = 'Registration failed, please try again';
        if (status === 422 && errors) {
          message = '';
          Object.values(errors).forEach((k: any) => {
            k.forEach((v: any) => {
              message += `- ${v}<br/>`;
            });
          });
        }
        saveAuth(undefined);
        setStatus(message);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const handleTypeChange = (e: any) => {
    setType(e.target.value);
    formik.resetForm();
  };

  const handlePasswordToggle = (e: any) => {
    setPasswordToggle(!passwordToggle);
  };

  if (registered) {
    return (
      <div>
        <h1 className="text-dark fw-bolder mb-3">Registration Complete!</h1>
        <p>An email verification has been sent, please verify your email.</p>
        <p>
          <Link to="/auth">Sign In</Link>
        </p>
      </div>
    );
  }

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="distro_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{parse(formik.status)}</div>
        </div>
      )}

      <div className="fv-row">
        {type ? (
          <label className="form-label fs-6">Account Type</label>
        ) : (
          <p className="text-center fs-3 fw-bold">Pick your Account Type</p>
        )}

        <div className="row">
          <div className="col-lg-6">
            <input
              {...formik.getFieldProps('account_type')}
              onClick={handleTypeChange}
              type="radio"
              className="btn-check"
              value="customer"
              id="kt_create_account_form_account_type_customer"
            />
            <label
              className={`btn btn-outline ${
                type == 'customer' ? 'btn-outline-primary' : ''
              } btn-outline-default p-7 d-flex align-items-center mb-10`}
              htmlFor="kt_create_account_form_account_type_customer"
            >
              <span className="d-block text-center ">
                <span className=" fw-bolder  d-block fs-1 mb-1">CUSTOMER</span>
                <span className=" d-block fs-6 mb-0 ">
                  Find, Hire, and Pay™ qualified candidates for full-time long-term roles.
                </span>
              </span>
            </label>
          </div>
          <div className="col-lg-6">
            <input
              {...formik.getFieldProps('account_type')}
              onClick={handleTypeChange}
              value="candidate"
              type="radio"
              className="btn-check"
              id="kt_create_account_form_account_type_candidate"
            />
            <label
              className={`btn btn-outline ${
                type == 'candidate' ? 'btn-outline-primary' : ''
              } btn-outline-default p-7 d-flex align-items-center mb-10`}
              htmlFor="kt_create_account_form_account_type_candidate"
            >
              <span className="d-block text-center">
                <span className=" fw-bolder d-block fs-1 mb-1">CANDIDATE</span>
                <span className=" d-block fs-6 mb-0">
                  Your skills are needed. Get hired, get paid and join growing remote teams!
                </span>
              </span>
            </label>
          </div>
        </div>
      </div>

      {type == 'customer' && (
        <>
          <div className="fv-row mb-6">
            <label className="form-label fs-6">Organization Name</label>
            <input
              type="text"
              autoComplete="off"
              {...formik.getFieldProps('business_name')}
              className={clsx('form-control bg-transparent')}
            />
            {formik.touched.business_name && formik.errors.business_name && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.business_name}</span>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {type == 'candidate' && (
        <>
          <div className="fv-row mb-6">
            <label className="form-label fs-6">Position Applying For</label>
            <select
              className="form-control bg-transparent !box-border !border !border-[#e4e6ef] d-flex align-items-center !rounded-[6px] !w-full !p-[10px] !min-w-[60px]"
              {...formik.getFieldProps('desired_role')}
              placeholder="Select a postion for which you are applying"
              name="desired_role"
              style={{ border: '1px solid #e4e6ef !important', width: '100% !important' }}
            >
              <option value=""></option>
              {FlattenedJobRoles(jobRoles)
                .sort(function (a, b) {
                  if (a.label < b.label) {
                    return -1;
                  }
                  if (a.label > b.label) {
                    return 1;
                  }
                  return 0;
                })
                .map((e) => {
                  return (
                    <option key={e.value} value={e.value}>
                      {e.label}
                    </option>
                  );
                })}
            </select>
            {formik.touched.desired_role && formik.errors.desired_role && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.desired_role}</span>
                </div>
              </div>
            )}
          </div>
          <div className="fv-row mb-6">
            <label className="form-label fs-6">Resume</label>
            <input
              type="file"
              accept="application/msword, text/plain, application/pdf"
              autoComplete="off"
              onFocus={() => formik.setTouched({ ...formik.touched, ['resume']: true })}
              onChange={(event) => {
                // @ts-ignore
                const file = event.target.files[0];
                formik.setFieldValue('resume', file);
              }}
              className={clsx('form-control bg-transparent')}
            />
            {formik.touched.resume && formik.errors.resume && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.resume}</span>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {type && (
        <>
          <div className="fv-row mb-6">
            <div className="row">
              <div className="col-lg-6">
                <label className="form-label fs-6">First Name</label>
                <input
                  type="text"
                  autoComplete="off"
                  {...formik.getFieldProps('firstname')}
                  className={clsx('form-control bg-transparent')}
                />
                {formik.touched.firstname && formik.errors.firstname && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.firstname}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-lg-6">
                <label className="form-label fs-6">Last name</label>
                <input
                  type="text"
                  autoComplete="off"
                  {...formik.getFieldProps('lastname')}
                  className={clsx('form-control bg-transparent')}
                />
                {formik.touched.lastname && formik.errors.lastname && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.lastname}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="fv-row mb-6">
            <label className="form-label fs-6">Email</label>
            <input
              type="email"
              autoComplete="off"
              {...formik.getFieldProps('email')}
              className={clsx('form-control bg-transparent')}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>

          <div className="fv-row mb-6" data-distro-password-meter="true">
            <div className="mb-1">
              <label className="form-label fs-6">Password</label>
              <div className="position-relative mb-3">
                <div className="input-group mb-3">
                  <input
                    type={passwordToggle ? 'input' : 'password'}
                    autoComplete="off"
                    {...formik.getFieldProps('password')}
                    className={clsx('form-control bg-transparent')}
                  />
                  <button
                    onClick={handlePasswordToggle}
                    className="btn btn-outline"
                    type="button"
                    id="button-addon2"
                  >
                    <i className={`fa-solid ${passwordToggle ? 'fa-eye-slash' : 'fa-eye'}`} />
                  </button>
                </div>
                {formik.touched.password && formik.errors.password && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="text-center">
            <AnimatedButton
              type="submit"
              processing={loading}
              id="distro_sign_up_submit"
              className="btn btn-lg btn-primary w-100 mb-0"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              <span className="indicator-label">Create Account</span>
            </AnimatedButton>
          </div>
          <div className="text-gray-700 text-center mb-0">
            <p>
              By creating an account, you agree to our{' '}
              <a
                className="link-primary "
                href="https://distro.io/terms-and-conditions/"
                target="_blank"
              >
                terms and conditions
              </a>
              .
            </p>
          </div>
        </>
      )}
    </form>
  );
}
