import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { MasterLayout } from '../../_theme/layout/MasterLayout';
import { OwnerAdminManagerRoute } from './OwnerAdminManagerRoute';
import { BillingRoute } from './BillingRoute';
import { lazyRetry } from '../util/lazyreload';
import { OwnerAdminRoute } from './OwnerAdminRoute';

const DashboardPage = lazy(() =>
  lazyRetry(() => import('../modules/organization/dashboard/DashboardPage'))
);
const JobsPage = lazy(() => lazyRetry(() => import('../modules/organization/jobs/JobsPage')));
const SettingsPage = lazy(() =>
  lazyRetry(() => import('../modules/organization/settings/SettingsPage'))
);
const AllCandidatesPage = lazy(() =>
  lazyRetry(() => import('../modules/organization/candidates/CandidatesPage'))
);
const HiresPage = lazy(() => lazyRetry(() => import('../modules/organization/hires/HiresPage')));
const NotificationsPage = lazy(() =>
  lazyRetry(() => import('../modules/notifications/NotificationsPage'))
);
const PayrollPage = lazy(() =>
  lazyRetry(() => import('../modules/organization/payroll/PayrollPage'))
);
const CalendarPage = lazy(() =>
  lazyRetry(() => import('../modules/organization/calendar/CalendarPage'))
);
const CustomerRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path="dashboard/*"
          element={
            <OwnerAdminManagerRoute>
              {' '}
              <DashboardPage />{' '}
            </OwnerAdminManagerRoute>
          }
        />
        <Route
          path="jobs/*"
          element={
            <OwnerAdminManagerRoute>
              {' '}
              <JobsPage />{' '}
            </OwnerAdminManagerRoute>
          }
        />
        <Route
          path="hires/*"
          element={
            <OwnerAdminManagerRoute>
              {' '}
              <HiresPage />{' '}
            </OwnerAdminManagerRoute>
          }
        />
        <Route
          path="calendar/*"
          element={
            <OwnerAdminManagerRoute>
              {' '}
              <CalendarPage />{' '}
            </OwnerAdminManagerRoute>
          }
        />
        <Route
          path="candidates/*"
          element={
            <OwnerAdminManagerRoute>
              <AllCandidatesPage />
            </OwnerAdminManagerRoute>
          }
        />
        <Route
          path="payroll/*"
          element={
            <BillingRoute>
              <PayrollPage />
            </BillingRoute>
          }
        />
        <Route
          path="settings/*"
          element={
            <OwnerAdminRoute>
              {' '}
              <SettingsPage />{' '}
            </OwnerAdminRoute>
          }
        />
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

export { CustomerRoutes };
