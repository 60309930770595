import { lazy } from 'react';

/**
 * An attempt to address the chunk reload failure that occasionally happens, as a result
 * of the browser loading a cached js file that no longer exists.
 *
 * https://www.codemzy.com/blog/fix-chunkloaderror-react
 * @param componentImport
 */

// const lazyWithRetry = (componentImport: any) =>
//   lazy(async () => {
//     const pageHasAlreadyBeenForceRefreshed = JSON.parse(
//       window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
//     );
//
//     try {
//       const component = await componentImport();
//
//       window.localStorage.setItem('page-has-been-force-refreshed', 'false');
//
//       return component;
//     } catch (error) {
//       if (!pageHasAlreadyBeenForceRefreshed) {
//         // Assuming that the user is not on the latest version of the application.
//         // Let's refresh the page immediately.
//         window.localStorage.setItem('page-has-been-force-refreshed', 'true');
//         return window.location.reload();
//       }
//
//       // The page has already been reloaded
//       // Assuming that user is already using the latest version of the application.
//       // Let's let the application crash and raise the error.
//       throw error;
//     }
//   });

export const lazyRetry = function (componentImport: any): any {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    componentImport()
      .then((component: any) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error: any) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};
