import React from 'react';
import { Navigate } from 'react-router';
import { useAuth } from '../../app/modules/auth';
import * as Sentry from '@sentry/react';
import { PrivateRoute } from './PrivateRoute';

type Props = {
  children: JSX.Element;
  user_type_guard: string;
};

export const PrivateRouteByUserType: React.FC<Props> = ({ user_type_guard, children }) => {
  const { accountType } = useAuth();

  if (accountType !== user_type_guard) {
    const message = `Account Type:[${accountType}], Guard Type:[${user_type_guard}]`;
    Sentry.captureMessage(message);

    return <Navigate to="/error/500" />;
  }

  return <PrivateRoute>{children}</PrivateRoute>;
};
