import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Content } from './components/content';
import { ThemeModeProvider } from '../partials';
import { PageDataProvider } from './core';
import { reInitMenu } from '../helpers';
import { AdminHeader } from './components/header/AdminHeader';

const SuperAdminLayout = () => {
  const location = useLocation();
  useEffect(() => {
    reInitMenu();
  }, [location.key]);

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className="d-flex flex-column flex-root app-root superadmin-root" id="distro_app_root">
          <AdminHeader />
          <Content>
            <Outlet />
          </Content>
        </div>
      </ThemeModeProvider>
    </PageDataProvider>
  );
};

export { SuperAdminLayout };
