import axios from 'axios';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Config } from '../../../../../config';
import { useQuery } from 'react-query';
import { useAuth } from '../../../auth';

export const InterviewsNav = () => {
  const { currentUser } = useAuth();
  const fetchJobs = async () => {
    const response = await axios.get(`${Config.api_url}/candidate/interview-jobs`);
    return await response.data.value;
  };

  const { data: interviewsData } = useQuery(
    ['candidate.interview.jobs', currentUser?.id],
    fetchJobs
  );

  const fetchHistory = async () => {
    const response = await axios.get(`${Config.api_url}/candidate/interviews/history`);
    return await response.data.value;
  };

  const { data: historyData } = useQuery(
    ['candidate.interview.history', currentUser?.id],
    fetchHistory
  );

  return (
    <>
      <div className="card-header !min-h-[40px] !p-0 !m-0 !mt-2">
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-bolder">
          <li className="nav-item h-12">
            <NavLink
              end
              to={`/candidate/interviews`}
              className="nav-link text-active-dark text-hover-dark !font-bold !text-body15"
            >
              Interviews{' '}
              <span className="!text-body14 !font-normal">({interviewsData?.length})</span>
            </NavLink>
          </li>
          <li className="nav-item h-12">
            <NavLink
              end
              to={`/candidate/interviews/history`}
              className="nav-link text-active-dark text-hover-dark !font-bold !text-body15"
            >
              History
              <span className="!text-body14 !font-normal">({historyData?.length || 0})</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};
