export interface AuthModel {
  access_token: string;
  expires_in: number;
  user: UserModel;
}

export interface UserModel {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  user_type: undefined | UserTypeEnum;
  hash: string;
  organization_id: string | null;
  organization_candidate_id: string | null;
  roles: Array<string>;
  superadmin: boolean;
  organization_name: string | null;
  profile_status: {
    complete: boolean;
    completion_status: {
      bio_complete: boolean;
      unique_avatar: boolean;
      skills_complete: boolean;
      years_professional_experience_complete: boolean;
      video_resume: boolean;
    };
  };
}

export enum UserTypeEnum {
  CUSTOMER = 'customer',
  CANDIDATE = 'candidate',
}
