/* eslint-disable react/jsx-no-target-blank */
import { useAuth } from '../../../../app/modules/auth';
import { KTSVG } from '../../../helpers';

const SidebarFooter = () => {
  const { logout } = useAuth();
  return (
    <div
      className={`app-sidebar-footer flex-column-auto pt-2 pb-6 px-6 !bg-priDark !text-white`}
      id="distro_app_sidebar_footer"
    >
      <button
        onClick={logout}
        className={`w-100 main-button !text-left bg-priBlack rounded-[12px] flex items-center`}
      >
        <i className="bi bi-box-arrow-right !text-alert !text-[20px] mr-2" />
        <span className="text-body14 font-semibold">Logout</span>
      </button>
    </div>
  );
};

export { SidebarFooter };
