import React, { FC, lazy } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { PageLink, PageTitle, LayoutToolbarButtons } from '../../../_theme/layout/core';
import { MasterLayout } from '../../../_theme/layout/MasterLayout';
import { lazyRetry } from '../../util/lazyreload';
import { EmailNotificationSettings } from './EmailNotificationSettings';

const Password = lazy(() => lazyRetry(() => import('./Password')));
const Account = lazy(() => lazyRetry(() => import('./Account')));

const AccountPage: FC = () => {
  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Account',
      path: '/account',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '--',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Password',
      path: '/account/password',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '--',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    <>
      <LayoutToolbarButtons>
        <div className="flex flex-col">
          <h1 className="text-h1 font-extrabold">My Profile</h1>
          <Link className="text-body14 font-medium text-lightGray2" to={`/candidate/welcome`}>
            Dashboard
          </Link>
        </div>
      </LayoutToolbarButtons>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route
            path="/"
            element={
              <>
                <PageTitle breadcrumbs={breadCrumbs}>Account</PageTitle>
                <Account />
              </>
            }
          />
          <Route
            path="/password"
            element={
              <>
                <PageTitle breadcrumbs={breadCrumbs}>Password</PageTitle>
                <Password />
              </>
            }
          />
          <Route
            path="/email_notifications"
            element={
              <>
                {/* <PageTitle breadcrumbs={breadCrumbs}>Password</PageTitle> */}
                <EmailNotificationSettings />
              </>
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default AccountPage;
