import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { PageLink, PageTitle } from '../../_theme/layout/core';
import { useAuth } from '../modules/auth';
import { SuperAdminLayout } from '../../_theme/layout/SuperAdminLayout';
import { Overview } from '../modules/./superadmin/Overview';
import * as Sentry from '@sentry/react';

const SuperAdminRoutes = () => {
  const { isSuperAdmin, currentUser } = useAuth();
  if (!isSuperAdmin) {
    return <Navigate to="/" />;
  }

  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Overview',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <Routes>
      <Route element={<SuperAdminLayout />}>
        <Route element={<Outlet />}>
          <Route
            path="/"
            element={
              <>
                <PageTitle breadcrumbs={breadCrumbs}>Super Admin</PageTitle>
                <Overview user_id={currentUser?.id as string} />
              </>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

export { SuperAdminRoutes };
