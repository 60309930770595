/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../_theme/layout/core';
import axios from 'axios';
import { Config } from '../../../config';
import toast from 'react-hot-toast';
import { AnimatedButton } from '../../../_theme/helpers/components/AnimatedButton';

const ProvideFeedback = () => {
  const [checkingToken, setCheckingToken] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [orgName, setOrgName] = useState<string>();
  const [feedback, setFeedback] = useState<string>();
  const { id } = useParams();
  const navigate = useNavigate();

  const processFeedback = async () => {
    try {
      setProcessing(true);
      await axios.post(`${Config.api_url}/feedback_request/${id}`, { feedback: feedback });
      toast.success('Thank you for your feedback!');
      navigate('/');
    } catch (e: any) {
      toast.error(e.response.data.message);
      setProcessing(false);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(`${Config.api_url}/feedback_request/${id}`);
        setOrgName(response.data.value.name);
        setCheckingToken(false);
      } catch (e: any) {
        toast.error(e.response.data.message);
        navigate('/');
      }
    };
    fetch();
  }, [id]);

  if (checkingToken) {
    return <LayoutSplashScreen />;
  }

  return (
    <div className="d-flex flex-column flex-lg-row flex-column-fluid h-100">
      <div className="d-flex flex-column flex-lg-row-fluid w-lg-7 p-10 order-2 order-lg-1">
        <div className="d-flex flex-center flex-column flex-lg-row-fluid">
          <div className="w-lg-900px p-10 text-center">
            <a href={'https://distro.io'}>
              <img
                src={'/media/logos/logo_dark_prpl.svg'}
                className={'mb-5'}
                style={{ border: 'none', width: '125px' }}
                alt={'DISTRO'}
              />
            </a>
            <div className={'card'}>
              <div className={'card-body'}>
                <p>
                  {' '}
                  Please take a few moments to share your thoughts on your time with{' '}
                  <b>{orgName}</b>. You can provide feedback on various aspects, such as:
                </p>
                <ul>
                  <li>
                    Your overall experience at <b>{orgName}</b>.
                  </li>
                  <li>Your interactions with colleagues and supervisors.</li>
                  <li>Any challenges you encountered during your tenure.</li>
                  <li>
                    Suggestions for improvements or changes you believe would benefit the
                    organization.
                  </li>
                  <li>
                    Anything else you would like to share regarding your time at <b>{orgName}</b>.
                  </li>
                </ul>
                <p>
                  Your feedback will be compiled and reviewed internally to help us make informed
                  decisions and implement positive changes.
                </p>
                <div className="separator   separator-solid mb-7 mt-5"></div>
                <textarea
                  placeholder={'Enter your feedback here'}
                  rows={7}
                  className={'form-control mb-7'}
                  onChange={(e) => setFeedback(e.target.value)}
                />
                <AnimatedButton
                  processing={processing}
                  disabled={processing}
                  onClick={processFeedback}
                  className={'btn btn-primary'}
                >
                  Share Feedback
                </AnimatedButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProvideFeedback };
