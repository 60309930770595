/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { SidebarMenuItem } from './SidebarMenuItem';
import { useAuth } from '../../../../../app/modules/auth';

const CustomerSidebarMainMenu = () => {
  const { roles } = useAuth();

  return (
    <div className="px-3">
      {(roles?.includes('owner') ||
        roles?.includes('admin') ||
        roles?.includes('manager') ||
        roles?.includes('peer')) && (
        <>
          {(roles?.includes('owner') || roles?.includes('admin') || roles?.includes('manager')) && (
            <SidebarMenuItem
              to="/customer/dashboard"
              icon="/media/icons/duotune/art/art002.svg"
              title="Dashboard"
              fontIcon="bi bi-house"
            />
          )}
          <SidebarMenuItem
            to="/customer/jobs"
            icon="fa-people-group"
            title="Jobs"
            fontIcon="bi bi-briefcase"
          />
          <SidebarMenuItem
            to="/customer/candidates"
            icon=""
            title="Candidates"
            fontIcon="bi bi-people"
          />
          <SidebarMenuItem
            to="/customer/calendar"
            icon="fa-calendar-days"
            title="Calendar"
            fontIcon="bi bi-calendar-event"
          />
          {(roles?.includes('owner') || roles?.includes('admin') || roles?.includes('manager')) && (
            <SidebarMenuItem
              to="/customer/hires"
              icon=""
              title="Hires"
              fontIcon="bi bi-person-check"
            />
          )}
        </>
      )}
      {(roles?.includes('owner') || roles?.includes('admin') || roles?.includes('billing')) && (
        <>
          <SidebarMenuItem
            to="/customer/payroll"
            icon=""
            title="Payments"
            fontIcon="bi bi-receipt "
          />
        </>
      )}
      {(roles?.includes('owner') || roles?.includes('admin')) && (
        <SidebarMenuItem
          to="/customer/settings"
          icon=""
          title="Organization"
          fontIcon="bi bi-building"
        />
      )}
    </div>
  );
};

export { CustomerSidebarMainMenu };
